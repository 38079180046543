import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "create-a-tab"
    }}>{`Create a Tab`}</h1>
    <hr></hr>
    <p>{`To create a Tab for your mobile app, follow these step-by-step instructions:`}</p>
    <ol>
      <li parentName="ol">{`Access the Content Management System and navigate to the Mobile Apps section from the left side menu options. Click on the mobile app where you want to create a new tab.  `}</li>
      <li parentName="ol">{`Select the tab in which you want to nest the new tab. `}
        <ul parentName="li">
          <li parentName="ul">{`Selecting the Homescreen tab will insert the new tab as a main tab displayed within your app. `}</li>
          <li parentName="ul">{`Selecting any other tab will nest your tab as a secdonary tab within your app. `}</li>
        </ul>
      </li>
      <li parentName="ol">{`Select 'Create' at the tab of the Tab Manager. `}</li>
      <li parentName="ol">{`Provide a name for your new tab. `}</li>
      <li parentName="ol">{`Either select 'Create' to generate your new tab or 'Cancel' to abort the creation.  `}</li>
    </ol>
    <p><img alt="Tab Manager Create" src={require("./images/create_tab.gif")} />{` `}</p>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`Click 'Save' at the bottom of the page to update your app to display your new tab. `}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      